import React from "react"

//icons 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

//  https://medium.com/@subalerts/implememting-a-simple-collapsible-component-in-react-js-67c796e64652
class Collapsible extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: false
        }
        this.togglePanel = this.togglePanel.bind(this);
    }
    togglePanel(e){
        this.setState({open: !this.state.open})
    }

    render() {
    return (
    <div>
        <div  className="question">
            {this.props.question} 
            <span onClick={(e)=>this.togglePanel(e)}> {this.state.open ? <FontAwesomeIcon icon={faMinus}/> : <FontAwesomeIcon icon={faPlus}/>}</span> 
        </div>
        {this.state.open ? (<div className="answer"> {this.props.answer}  </div>) : null}
    </div>);
    }
}
export default Collapsible