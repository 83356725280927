import React  from "react"
import { Link } from 'gatsby'

//styles
import "../styles/index.css"
import "../styles/header.css"
import "../styles/banner.css"
import "../styles/footer.css"
import "../styles/sbanner.css"
import "../styles/services.css"
import "../styles/servicesTemp.css"
import "../styles/contact.css"
import "../styles/careers.css"
import "../styles/serviceCard.css"
import "../styles/ourStory.css"
import "../styles/blog.css"

//testing. remove later
// components
import Header from "../components/Header.js";
import Banner from "../components/Banner.js"
import Footer from "../components/Footer.js";
import ServiceCard from "../components/ServiceCard.js";

// components
import Layout from "../components/Layout";
import Collapsible from "../components/Collapsible.js";

//images
import care1 from '../images/care1.png';
import care2 from '../images/care2.png';

// data 
import data from "../data/data.json"

const ServicesPage = () => {
  
  // First column under Personal Care
  let div1 = []
  for(let i = 0; i < 5; i++){

    // Links to subpages
    // div1.push(<li><Link to={"../our-services/"+data[i].title.split(' ')[0].toLowerCase()}>{data[i].title}</Link></li>)

    div1.push(<li>{data[i].title}</li>)
  } 

  // Second column under Personal Care
  let div2 = []
  for(let i = 5; i < 8; i++){
    
    // Links to subpages
    // div2.push(<li><Link to={"../our-services/"+data[i].title.split(' ')[0].toLowerCase()}>{data[i].title}</Link></li>)

    div2.push(<li>{data[i].title}</li>)
  } 

  // First column under Domestic Care
  let div3 = []
  for(let i = 8; i <= 11; i++){
   
    // Links to subpages
    // div3.push(<li><Link to={"../our-services/"+data[i].title.split(' ')[0].toLowerCase()}>{data[i].title}</Link></li>)

    div3.push(<li>{data[i].title}</li>)
  } 

  // Second column under Domestic Care
  let div4 = []
  for(let i = 12; i < data.length - 1; i++){

    // Links to subpages
    // div4.push(<li><Link to={"../our-services/"+data[i].title.split(' ')[0].toLowerCase()}>{data[i].title}</Link></li>)

    div4.push(<li>{data[i].title}</li>)
  } 

  return (
      <Layout title="Our Services" path="/ Our Services">
      <title>Co-op Homecare | Services</title>
      <section id="serviceTypes">
        <div className="center-column-to-row" id="personal">
          <div>
            <h1>Personal Care </h1>
            <img src={care2} alt="Elderly Couple"></img>
          </div>
          <div className="serviceDescrip">
          <p> Personal care services include basic personal hygiene and activities of daily living. Personal care services can include tasks, such as mobility and ambulation, grooming and hygiene, incontinence and toileting care, and dressing. Additionally, personal care services include companionship and community outings. Our caregivers can provide full hands-on care or stand-by assistance depending on the client’s needs.</p>
            <ul>
              <div> {div1} </div>
              <div>
                {div2}
                <li> <Link to="/contact" class="contact-service">Contact Us</Link></li>
              </div>
            </ul>
          </div>
        </div>
      
        <div className="center-column-to-row" id="domestic">
          <div>
            <h1>Domestic Care</h1>
            <img src={care1} alt="Lady and Elderly Man Reading"></img>
          </div>
          <div className="serviceDescrip">
            <p> Domestic care services include help and support around the clients home. This includes tasks, such as laundry, shopping and errands, household maintenance,cooking and meal preparation, and pet care.</p>
            <ul>
              <div>{div3}</div>
              <div>
                {div4}
                <li> <Link to="/contact" class="contact-service">Contact Us</Link></li>
              </div>
            </ul>
          </div>
        </div>
      </section>

      <section className="center-row" id="faq-parent">
        <div id="faq-content">
          <h1>Homecare FAQ</h1>
          <div>
            <Collapsible question="What is homecare?" 
                   answer="Home care services provide assistance to those who are aging and want to live independently in the privacy of their own homes. Home care services are also critical for individuals who are managing chronic health issues, recovering from a medical procedure, or have special needs or a disability.
                   "/>
            <Collapsible question="What type of homecare is best for me or my loved one?" 
                   answer="Our Home Care Services are tailored to each individual’s needs. We provide a comprehensive assessment with all of our clients to determine what type of care is best, how many hours per day, and days per week."/>
            <Collapsible question="How quickly can services start?" 
                   answer="Depending on the need, services can start as soon as possible. We dedicate ourselves to matching our client's needs with the perfect care professional."/>
          </div>
        </div>
      </section>

      </Layout>
  )
}

export default ServicesPage
